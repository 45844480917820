export function slugify(name) {
  name = name.toLowerCase();
  name = name.replace(/ä/g, 'ae');
  name = name.replace(/ö/g, 'oe');
  name = name.replace(/ü/g, 'ue');
  name = name.replace(/ü/g, 'ue');
  name = name.replace(/ß/g, 'ss');
  name = name.replace(/á|à|ă|â|å|æ|ã|ā/g, 'a');
  name = name.replace(/č|ç|ć/g, 'c');
  name = name.replace(/é|è|ē|ę|ė|ë|ê/g, 'e');
  name = name.replace(/ñ|ń/g, 'n');
  name = name.replace(/š|ś/g, 's');
  name = name.replace(/ó|ò|ő|ø/g, 'o');
  name = name.replace(/ú|ù|û|ū/g, 'u');
  name = name.replace(/ț/g, 't');
  name = name.replace(/î|ì|ï/g, 'i');
  name = name.replace(/ÿ/g, 'y');
  name = name.replace(/'/g, '');
  name = name.replace(/\)/g, '');
  name = name.replace(/\(/g, '');
  name = name.replace(/\s/g, '');
  return name;
}
