import React from 'react';

const Checkbox = props => {
  return (
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          checked={props.checked}
          onChange={props.handleChange}
          value={props.value}
          {...props}
        />
        {props.title}
      </label>
    </div>
  );
};

export default Checkbox;
