import React from 'react';
import { slugify } from '../Signature/Slugify';

class MonsterImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  handleImageLoaded = event => {
    this.setState({ loaded: true });
  };

  render() {
    const monsterName = slugify(
      `${this.props.passState.firstName}-${this.props.passState.lastName}`,
    );
    const monster = this.props.passState.addFreelancerMonster ? 'freelancer-monster' : monsterName
    const url = `https://assets.mysugr.com/email/signature-monsters/avatar-${monster}-140x140.png`;

    return (
      <div data-test="image" style={{ zIndex: 0, display: 'table', width: '108px', textAlign: 'center' }}>
        <div
          style={{
            backgroundColor: 'rgb(163,163,163,0.05)',
            height: '108px',
            borderRadius: 50,
            zIndex: 0,
            display: this.state.loaded ? 'table-cell' : 'none',
            verticalAlign: 'middle',
          }}
        >
          {' '}
          <img
            src={url}
            onLoad={this.handleImageLoaded}
            onError={e => {
              e.target.src =
                'https://assets.mysugr.com/email/signature-monsters/avatar-logo-trademark-140x140.png';
            }}
            height="80px"
            width="80px"
            style={{
              display: this.state.loaded ? '' : 'none',
              opacity: 1,
            }}
            className="monsterPhoto"
            alt="monster"
          ></img>
        </div>
      </div>
    );
  }
}
export default MonsterImage;
